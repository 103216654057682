export async function hashData(data: ArrayBuffer): Promise<string> {
  const hashedData = await window.crypto.subtle.digest("SHA-256", data);
  const byteArray = Array.from(new Uint8Array(hashedData));

  return byteArray
    .map((byte) => {
      return byte.toString(16).padStart(2, "0");
    })
    .join("");
}

export async function hashString(data: string): Promise<string> {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(data);
  return hashData(bytes);
}
