import { useTheme } from "@/components/theme-picker";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { qualityToSlug, sortQualities, VideoMetadata } from "@/lib/video";

export function AvailableQualityBadges(
  video: VideoMetadata,
): JSX.Element[] | null {
  const availableQualities = sortQualities(
    Object.getOwnPropertyNames(video.playlistBasePaths),
  );
  const badgeColors: Record<string, Record<string, string[]>> = {
    light: {
      "360": [
        "bg-amber-100",
        "hover:bg-amber-200",
        "text-amber-600",
        "border-1",
        "border-amber-200",
      ],
      "720": [
        "bg-orange-100",
        "hover:bg-orange-200",
        "text-orange-600",
        "border-1",
        "border-orange-200",
      ],
      "1080": [
        "bg-green-100",
        "hover:bg-green-200",
        "text-green-600",
        "border-1",
        "border-green-200",
      ],
      ORIGINAL: [
        "bg-blue-100",
        "hover:bg-blue-200",
        "text-blue-600",
        "border-1",
        "border-blue-200",
      ],
    },
    dark: {
      "360": [
        "bg-amber-900",
        "hover:bg-amber-900",
        "text-amber-200",
        "border-1",
        "border-amber-800",
      ],
      "720": [
        "bg-orange-900",
        "hover:bg-orange-900",
        "text-orange-200",
        "border-1",
        "border-orange-800",
      ],
      "1080": [
        "bg-green-900",
        "hover:bg-green-900",
        "text-green-200",
        "border-1",
        "border-green-800",
      ],
      ORIGINAL: [
        "bg-blue-900",
        "hover:bg-blue-900",
        "text-blue-200",
        "border-1",
        "border-blue-800",
      ],
    },
  };
  const theme = badgeColors[useTheme().theme] || badgeColors["light"];

  if (availableQualities.length > 0) {
    return availableQualities.map((quality) => {
      const classNames = cn(theme[quality], ["text-medium"]);
      const badge = (
        <Badge variant="secondary" key={quality} className={classNames}>
          {qualityToSlug(quality)}
        </Badge>
      );
      return badge;
    });
  } else {
    return null;
  }
}
