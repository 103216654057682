import React from "react";
import ReactDOM from "react-dom/client";

import IndexPage from "./pages";

import "./styles/globals.css";

import * as Sentry from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";
import {
  createBrowserRouter,
  defer,
  Link,
  LoaderFunctionArgs,
  NavLink,
  Outlet,
  redirect,
  RouterProvider,
  useLoaderData,
} from "react-router-dom";

import RequireNoSession from "./components/require-no-session";
import RequireSession from "./components/require-session";
import { ThemePicker, ThemeProvider } from "./components/theme-picker";
import { Separator } from "./components/ui/separator";
import { UserMenu } from "./components/user-menu";
import { loadUserInfo, UserInfo } from "./lib/auth";
import { isDev } from "./lib/feature_flags";
import { cn } from "./lib/utils";
import { loadOwnedVideoMetadata, loadVideoMetadata } from "./lib/video";
import AboutPage from "./pages/about";
import SigninPage from "./pages/signin";
import SignupPage from "./pages/signup";
import VideoManagerPage from "./pages/video/manager";
import MetadataEditorPage from "./pages/video/metadata-editor";
import WatchVideoPage from "./pages/watch";

function Header(props: { userInfo: UserInfo }) {
  const baseStyles = [
    "mb-6",
    "py-4",
    "sticky",
    "top-0",
    "z-50",
    "w-full",
    "border-b",
    "border-border/40",
    "bg-background/90",
    "backdrop-blur",
    "supports-[backdrop-filter]:bg-background/85",
  ];
  const headerStyles = cn(baseStyles);
  const devPrefix = isDev() ? "DEV // " : "";

  return (
    <div className={headerStyles}>
      <div className="container 2xl mx-auto flex flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-4 h-9">
          <header>
            <Link to="/">
              <h2 className="text-base font-bold">{devPrefix}Comfy Video</h2>
            </Link>
          </header>
          <nav>
            <NavLink
              to="/about"
              className="transition py-2 px-2 rounded ease-in-out hover:bg-black/5"
            >
              About
            </NavLink>
          </nav>
        </div>
        <div className="flex flex-row items-center space-x-4 h-9">
          <UserMenu userInfo={props.userInfo} />
          <Separator orientation="vertical" className="b-r" />
          <ThemePicker />
        </div>
      </div>
    </div>
  );
}

function Layout() {
  const userInfo = useLoaderData() as UserInfo;

  return (
    <>
      <Header userInfo={userInfo} />
      <Outlet context={[userInfo]} />
    </>
  );
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    loader: loadUserInfo,
    shouldRevalidate: () => true,
    children: [
      {
        path: "/",
        element: <IndexPage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/auth/signin",
        element: (
          <RequireNoSession>
            <SigninPage />
          </RequireNoSession>
        ),
      },
      {
        path: "/auth/signup",
        element: (
          <RequireNoSession>
            <SignupPage />
          </RequireNoSession>
        ),
      },
      {
        path: "/video-manager",
        element: (
          <RequireSession>
            <VideoManagerPage />
          </RequireSession>
        ),
        loader: async () => {
          const promise = loadOwnedVideoMetadata();
          return defer({
            videos: promise,
          });
        },
      },
      {
        path: "/video-manager/:videoSlug",
        element: <MetadataEditorPage />,
        loader: async ({ params }: LoaderFunctionArgs) => {
          return (
            (await loadVideoMetadata(params.videoSlug || "")) ||
            redirect("/404")
          );
        },
      },
      {
        path: "/v/:videoSlug",
        element: <WatchVideoPage />,
        loader: async ({ params }: LoaderFunctionArgs) => {
          return (
            (await loadVideoMetadata(params.videoSlug || "")) ||
            redirect("/404")
          );
        },
      },
    ],
  },
]);

Sentry.init({
  dsn: "https://c3d983a60ef06bd93139e1895b411849@o4507151803809792.ingest.us.sentry.io/4507151806300160",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/comfy\.video/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// @ts-expect-error The root element will always exist.
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <RouterProvider router={router} />
      </ThemeProvider>
    </HelmetProvider>
    <script type="module" src="/src/main.tsx"></script>
  </React.StrictMode>,
);
