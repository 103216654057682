import { doTurnstileChallenge } from "@/lib/auth";
import { CF_TURNSTILE_KEY } from "@/lib/constants";
import { chunkedUpload } from "@/lib/upload";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircleX } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Alert, AlertDescription } from "./ui/alert";
import { Input } from "./ui/input";
import { Progress } from "./ui/progress";

const MAX_UPLOAD_SIZE = 5 * 1024 * 1024 * 1024; // 5 GB
const CHUNKED_UPLOAD_SIZE = 100 * 1024 * 1024; // 100 MB

interface State {
  uploadProgress: number | null;
  error: string | null;
  cfChallengeResponse: string | null;
}

const ACCEPTED_MIME_TYPES = [
  "video/x-flv",
  "video/mp4",
  "video/x-msvideo",
  "video/quicktime",
  "video/webm",
  "video/x-ms-wmv",
];
const UploadSchema = z
  .instanceof(FileList)
  .refine((fileList) => {
    if (!fileList) return true;
    for (const file of fileList) {
      if (file.size > MAX_UPLOAD_SIZE) return false;
      if (file.size === 0) return false;
    }
    return true;
  }, "File size must be less than 5 GB")
  .refine((fileList) => {
    for (const file of fileList) {
      if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
        console.debug(`Encountered unaccepted mime type: ${file.type}`);
        return false;
      }
    }
    return true;
  }, "File type must be one of the ones mentioned above");

function VideoUploader() {
  const [state, setState] = useState<State>({
    uploadProgress: null,
    error: null,
    cfChallengeResponse: null,
  });
  const navigate = useNavigate();

  const turnstileBoxRef = useRef<HTMLDivElement>(null);
  const turnstileBox = (
    <div
      className={cn("cf-turnstile", "hidden")}
      data-sitekey={CF_TURNSTILE_KEY}
      data-callback="javascriptCallback"
      data-theme="auto"
      ref={turnstileBoxRef}
    ></div>
  );

  const uploadFile = async (blob: File): Promise<string> => {
    turnstileBoxRef.current?.classList.remove("hidden");
    const turnstileChallengeResp = await doTurnstileChallenge(
      // Guaranteed to not be null because the element needs to have
      // been rendered in order for this an upload to have been started.
      turnstileBoxRef.current!,
    );

    setState({
      ...state,
      uploadProgress: 0,
    });

    let uploadedBytes = 0;
    const totalBytes = blob.size;

    const uploadId = await chunkedUpload(
      turnstileChallengeResp,
      blob,
      CHUNKED_UPLOAD_SIZE,
      (uploaded) => {
        uploadedBytes += uploaded;
        setState({
          ...state,
          uploadProgress: (uploadedBytes / totalBytes) * 100,
        });
      },
    );

    setState({
      ...state,
      uploadProgress: 100,
    });

    return uploadId;
  };

  const formSchema = z.object({
    upload: UploadSchema,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isValidating },
  } = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    uploadFile(values.upload[0])
      .then((uploadId) => {
        navigate(`/video-manager/${uploadId}`);
      })
      .catch((e) => {
        setState({
          ...state,
          error: "" + e,
        });
      });
  };

  const uploadSubscription = watch("upload");

  // Here we use isValidating as an effect dependency because it seems
  // to be the best way to avoid this effect triggering multiple times.
  // We do not trigger based on uploadSubscription because focus changes
  // will trigger this effect potentially causing multiple uploads.
  useEffect(() => {
    if (state.uploadProgress !== null || state.error !== null) {
      return;
    }
    if (isValid && !isValidating && uploadSubscription.length) {
      handleSubmit(onSubmit)();
    }
  }, [isValidating, uploadSubscription]);

  let dialogContent;

  const errorBox = !state.error ? null : (
    <Alert className="text-white bg-red-500">
      <CircleX color="#ffffff" className="w-4 h-4" />
      <AlertDescription>{state.error}</AlertDescription>
    </Alert>
  );

  if (state.uploadProgress === null) {
    dialogContent = (
      <form
        method="POST"
        encType="multipart/form-data"
        action="/api/video"
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-6"
      >
        {errorBox}
        <div>
          <Input
            type="file"
            accept=".avi, .flv, .mov, .mp4, .webm, .wmv"
            {...register("upload", { required: true })}
          />
          <p className="text-sm text-muted-foreground">
            File must be one of: .avi, .flv, .mkv, .mov, .mp4, .webm, .wmv
          </p>
        </div>
        {errors.upload && (
          <p className="text-sm text-red-600">{errors.upload.message}</p>
        )}
        {turnstileBox}
      </form>
    );
  } else {
    dialogContent = <Progress value={state.uploadProgress}></Progress>;
  }

  return dialogContent;
}

export default VideoUploader;
