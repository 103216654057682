import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import VideoUploader from "@/components/video-uploader";
import { UserInfo } from "@/lib/auth";
import { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";

function UploadButton({ isLoggedIn }: { isLoggedIn: boolean }) {
  if (!isLoggedIn) {
    return (
      <Link to="/auth/signin?redirect=/video-manager">
        <Button size="lg">Share a Moment</Button>
      </Link>
    );
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button size="lg">Share a Moment</Button>
      </AlertDialogTrigger>
      <AlertDialogContent
        aria-describedby="An upload input box and a cancellation button."
        aria-description="An upload form where you can select a video file."
      >
        <AlertDialogHeader>
          <AlertDialogTitle>Choose a video to upload...</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="my-2">
          <VideoUploader />
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function IndexPage() {
  useEffect(() => {
    document.title = "Comfy Video";
  }, []);

  const [userInfo]: Array<UserInfo | null> = useOutletContext();
  const mustBeSignedIn =
    userInfo === null ? (
      <p className="text-xs text-gray-400">Must be signed in to upload.</p>
    ) : null;

  return (
    <div className="container space-y-16 content-center grow">
      <h4 className="text-6xl font-bold leading-none text-center">
        Video sharing should be fast and easy.
      </h4>
      <div className="flex items-center space-y-2 justify-center gap-y-2 flex-col">
        <UploadButton isLoggedIn={userInfo !== null} />
        {mustBeSignedIn}
      </div>
    </div>
  );
}

export default IndexPage;
