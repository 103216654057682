import "hls-video-element";

import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { Hls } from "hls-video-element";
import { useEffect, useRef } from "react";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

import { isDev } from "@/lib/feature_flags";

const AUTOPLAY_ON_HOVER_WAIT_MS = 300;

export enum PlayerType {
  Regular,
  Mini,
  Thumbnail,
}

interface HlsJsVideoPlayerProps {
  url: string;
  autoplay?: boolean;
  type?: PlayerType;
}

export function HlsJsVideoPlayer({
  url,
  autoplay = false,
  type = PlayerType.Regular,
}: HlsJsVideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  let dimensions = {};

  if (type == PlayerType.Mini) {
    dimensions = {
      width: "640",
      height: "360",
    };
  }

  const videoEle = (
    <video
      ref={videoRef}
      controls={type !== PlayerType.Thumbnail}
      muted={type === PlayerType.Thumbnail}
      preload="auto"
      {...dimensions}
    ></video>
  );

  useEffect(() => {
    const currentRef = videoRef.current;

    if (Hls.isSupported() && currentRef !== null) {
      const hls = new Hls({
        debug: isDev(),
      });

      hls.loadSource(url);
      hls.attachMedia(currentRef);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        if (autoplay === true) {
          currentRef.play();
        }
      });

      if (type === PlayerType.Thumbnail) {
        hls.pauseBuffering();
        // Only load a single fragment.
        hls.on(Hls.Events.FRAG_LOADED, () => {
          hls.stopLoad();
        });

        const queuedAutoplayTimeoutIds: NodeJS.Timeout[] = [];

        currentRef.addEventListener("mouseenter", () => {
          queuedAutoplayTimeoutIds.push(
            setTimeout(() => {
              hls.startLoad();
              currentRef.play();
            }, AUTOPLAY_ON_HOVER_WAIT_MS),
          );
        });
        currentRef.addEventListener("mouseleave", () => {
          queuedAutoplayTimeoutIds.forEach((timeoutId) => {
            clearTimeout(timeoutId);
            currentRef.pause();
            currentRef.currentTime = 0;
            hls.stopLoad();
          });
        });
      }
    } else if (currentRef !== null) {
      currentRef.src = url;
    }
  }, [url]);

  return (
    <div className="video-player aspect-video bg-black flex justify-center">
      {videoEle}
    </div>
  );
}

export function VidStackPlayer(props: HlsJsVideoPlayerProps) {
  return (
    <MediaPlayer
      src={props.url}
      autoPlay={props.autoplay}
      className="video-player"
    >
      <MediaProvider />
      <DefaultVideoLayout icons={defaultLayoutIcons} noModal={true} />
    </MediaPlayer>
  );
}
