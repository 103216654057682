import { UserInfo } from "@/lib/auth";
import { hashString } from "@/lib/crypto";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "./ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";
import { Skeleton } from "./ui/skeleton";

function GravatarIcon(props: { emailAddress: string }) {
  const [hashedEmail, setHashedEmail] = useState<string | null>(null);

  useEffect(() => {
    hashString(props.emailAddress.trim().toLowerCase()).then((hash) =>
      setHashedEmail(hash),
    );
  }, [props]);

  if (hashedEmail === null) {
    return <Skeleton className="aspect-square" />;
  } else {
    const fallback = encodeURIComponent(
      `https://api.dicebear.com/9.x/thumbs/png/seed=${hashedEmail}`,
    );
    return (
      <img
        src={`https://gravatar.com/avatar/${hashedEmail}?d=${fallback}`}
        className="h-full rounded cursor-pointer"
      />
    );
  }
}

export function UserMenu(props: { userInfo: UserInfo }) {
  if (!props.userInfo) {
    return (
      <>
        <Link to="/auth/signin">
          <Button variant="secondary">Sign In</Button>
        </Link>
        <Link to="/auth/signup">
          <Button>Sign Up</Button>
        </Link>
      </>
    );
  } else {
    return (
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger>
              <GravatarIcon emailAddress={props.userInfo.emailAddress} />
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className="p-2 md:w-[150px] lg:w-[200px]">
                <li>
                  <NavigationMenuLink>
                    <Link
                      to="/video-manager/"
                      className="block p-2 rounded-md hover:bg-skeleton/10 w-full"
                    >
                      Video Manager
                    </Link>
                  </NavigationMenuLink>
                </li>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    );
  }
}
