import { fetchCsrfToken } from "./auth";
import { CSRF_TOKEN_HEADER } from "./constants";
import { isDev } from "./feature_flags";

export interface ApiError {
  error: string;
}

export function getApiPrefix(): string {
  if (isDev()) {
    return location.origin;
  }

  return "";
}

export function apiUrl(uri: string): string {
  return `${getApiPrefix()}${uri}`;
}

export async function genBasicApiHeader(): Promise<Headers> {
  const csrfToken = await fetchCsrfToken();
  const headers: HeadersInit = new Headers();
  headers.set("Content-Type", "application/json");
  headers.set(CSRF_TOKEN_HEADER, csrfToken);

  return headers;
}
