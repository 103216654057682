import pRetry from "p-retry";

import { apiUrl } from "./api";

interface FetchFeatureFlagResponse {
  value: boolean | null;
}

export async function getFeatureFlag(
  name: string,
  defaultValue: boolean,
): Promise<boolean> {
  try {
    const resp = await pRetry(() => fetch(apiUrl(`/features/${name}`)), {
      retries: 3,
    });
    const result: FetchFeatureFlagResponse = await resp.json();
    return result.value === null ? defaultValue : result.value;
  } catch (err) {
    console.warn(
      `Failed to fetch feature flag (${name}) so the default value (${defaultValue}) will be returned: ${err}`,
    );
    return defaultValue;
  }
}

export function isDev(): boolean {
  return (
    import.meta.env.DEV ||
    location.hostname === "sandbox.comfy.video" ||
    location.hostname === "localhost"
  );
}
