import {
  ChangeEvent,
  TextareaHTMLAttributes,
  forwardRef,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Textarea } from "./textarea";

type AsyncEventHandler = (e: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;

interface AutoSaveTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  onChange: AsyncEventHandler;
}

const AutoSaveTextarea = forwardRef<HTMLTextAreaElement, AutoSaveTextAreaProps>(
  function AutoSaveTextarea({ onChange, ...props }, ref) {
    const [isWorking, setIsWorking] = useState(false);

    const suspense = (onChangeFn: AsyncEventHandler) => {
      const wrapper = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (!isWorking) setIsWorking(true);

        onChangeFn(e).finally(() => {
          setIsWorking(false);
        });
      };

      return wrapper;
    };

    const capturedOnChange =
      onChange ||
      (() => {
        return new Promise((resolve) => resolve());
      });
    const suspendedOnChange = suspense(capturedOnChange);
    const debouncedOnChange = useDebouncedCallback(suspendedOnChange, 500);

    const maybeLoaderIcon = isWorking ? (
      <FontAwesomeIcon icon={faCircleNotch} className="worker-indicator" />
    ) : null;
    const workingClass = isWorking ? "working" : "";

    return (
      <div
        className={`autosave-textarea ${workingClass}`}
        style={{ position: "relative" }}
      >
        <div className="screen rounded-md"></div>
        <Textarea ref={ref} onChange={debouncedOnChange} {...props}></Textarea>
        {maybeLoaderIcon}
      </div>
    );
  }
);

export default AutoSaveTextarea;
