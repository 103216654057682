import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import { useEffect } from "react";

function AboutPage() {
  useEffect(() => {
    document.title = "About Comfy Video";
  }, []);

  return (
    <div className="container py-16 space-y-8 grow">
      <h1 className="text-6xl font-bold leading-none">About Comfy Video</h1>
      <p>
        Comfy Video is a website for people to anonymously upload videos quickly
        and easily. With tons of video upload sites lowering the quality of
        service for their free tier or straight up deleting their free tier we
        sought to create an alternative website that we would want to use
        ourselves. We're not trying to create the next YouTube-killer or
        anything here all we want to do is create a comfy place to upload and
        share videos with friends.
      </p>
      <Separator />
      <section className="space-y-4">
        <h2 className="text-2xl leading-none">
          Heads up! We're still in early-preview mode.
        </h2>
        <p>
          This website and all its backing infrastructure is currently in
          early-preview mode. No guarantees are made with regards to uptime or
          quality of service but we'll try our best. Bugs and paper cuts are
          expected and we'll be opening a way to get feedback and bug reports in
          the coming future.
        </p>
      </section>
      <Separator />
      <section className="space-y-4">
        <h2 className="text-2xl leading-none">Known Issues</h2>
        <p>
          The following is a list of currently known issues. Some of these
          issues are already represented as work items in our roadmp (see
          below). Others are not represented as roadmap items but will be fixed.
          <ul className="list-disc">
            <li className="ml-8">
              There is no way to navigate to the video manager asides from being
              redirected <em>after</em> uploading a new video
            </li>
            <li className="ml-8">
              The password reset button on the sign-in page does nothing
            </li>
            <li className="ml-8">
              The video deletion button in the video manager does nothing
            </li>
            <li className="ml-8">
              Video descriptions are not displayed on the video playback page
            </li>
            <li className="ml-8">
              Video thumbnails may not load in certain conditions
            </li>
            <li className="ml-8">
              The highest available quality stream will always be used when
              viewing videos
            </li>
            <li className="ml-8">
              Page embedding on social media like Discord does not work
            </li>
            <li className="ml-8">
              Transcoding for extremely high resolution videos (1440p + 4k
              source) may take a long time due to insufficient CPU capacity
            </li>
          </ul>
        </p>
      </section>
      <Separator />
      <section className="space-y-4">
        <h2 className="text-2xl leading-none">Service Offerings and Limits</h2>
        <p>
          We want to make this a website that we want to use whenever we want to
          upload and share something. With that being said here are the goals
          that we're striving to achieve and how we're planning on doing it:
          <ul className="list-disc">
            <li className="ml-8">
              We will offer free video transcoding for all uploads up to and
              including source quality.
            </li>
            <li className="ml-8">
              We will allow the upload and playback of videos up to 5 GB in
              size.
            </li>
            <li className="ml-8">
              We will allow the upload and playback of videos up to and
              including 30 minutes in length.
            </li>
            <li className="ml-8">
              In order to keep the website up and running we may take donations
              or Patreon but there will never be ads nor will we lock essential
              features behind a premium tier.
            </li>
            <li className="ml-8">
              This website will be open and free to use by anyone however open
              registration is not guaranteed to be a perpetual offering and may
              only be offered for a limited period of time. We are doing this to
              control the website's rate of growth and ensure that we have the
              appropriate scale and capacity to continue offering a good
              experience.
            </li>
          </ul>
        </p>
      </section>
      <Separator />
      <section className="space-y-4">
        <h2 className="text-2xl leading-none">
          Current Roadmap (Jun 2024 - Dec 2024)
        </h2>
        <p>
          Below you will find our public roadmap. Note that a P0 goal is
          something that must be achieved or achieved be done with utmost
          priority (relative to other goals). A P1 goal is something that should
          be achieved but it doesn't matter if there are still P0 goals
          lingering. It might not look like that much work but this website is
          being worked on as a hobby and passion project (for free!)&mdash;stuff
          can happen.
        </p>
        <Accordion type="single" collapsible>
          <AccordionItem value="p0">
            <AccordionTrigger>P0 Goals</AccordionTrigger>
            <AccordionContent>
              <h4 className="text-base">Website</h4>
              <ul className="list-disc">
                <li className="ml-8">Implement video deletion functionality</li>
                <li className="ml-8">Implement password reset functionality</li>
                <li className="ml-8">Implement an account management system</li>
                <li className="ml-8">
                  Implement a quality selector for video playback
                </li>
              </ul>
              <h4 className="text-base">Infrastructure</h4>
              <ul className="list-disc">
                <li className="ml-8">Implement master playlist generation</li>
                <li className="ml-8">
                  Implemented distributed video transcoding to improve overall
                  hardware utilization
                </li>
              </ul>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="p1">
            <AccordionTrigger>P1 Goals</AccordionTrigger>
            <AccordionContent>
              <h4 className="text-base">Website</h4>
              <ul className="list-disc">
                <li className="ml-8">Implement thumbnail picking</li>
              </ul>
              <h4 className="text-base">Infrastructure</h4>
              <ul className="list-disc">
                <li className="ml-8">Implement thumbnail generation</li>
                <li className="ml-8">
                  Implement automatic cleanup of unused assets
                </li>
                <li className="ml-8">
                  Laundry list of internal infrastructure improvements...
                </li>
              </ul>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </section>
      <section className="space-y-4">
        <h2 className="text-2xl leading-none">Financial Transparency Report</h2>
        <p>
          We want to keep this website free for everyone and are potentially
          looking at opening up donations or a Patreon in the future. In order
          to remain transparent about where money is coming from and going to
          this monthly transparency report will remain on this page.
        </p>
        <h4>Income</h4>
        <ul className="list-disc">
          <li className="ml-8">Self Funding - $560.92 USD</li>
        </ul>
        <h4>Expenses</h4>
        <ul className="list-disc">
          <li className="ml-8">Database Server (1x): $30 USD</li>
          <li className="ml-8">Cache Server (1x): $15 USD</li>
          <li className="ml-8">
            Kubernetes Cluster
            <ul className="list-disc">
              <li className="ml-8">
                Compute Servers (5x): $480 USD ($96 USD each)
                <ul>
                  <li className="ml-8">
                    Type 1: 8 vCPU, 16 GB memory, 2 GB/s NIC
                  </li>
                </ul>
              </li>
              <li className="ml-8">Persistent Volume Block Storage: $23.50</li>
            </ul>
          </li>
          <li className="ml-8">Load Balancer, Web (1x): $12 USD</li>
          <li className="ml-8">Cloudflare R2: $0.42 USD</li>
        </ul>
      </section>
    </div>
  );
}

export default AboutPage;
