import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { apiUrl, genBasicApiHeader } from "@/lib/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircleX } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

function SignupForm() {
  const [state, setState] = useState<{
    isInFlight: boolean;
    serverErrors: Array<string>;
  }>({
    isInFlight: false,
    serverErrors: [],
  });

  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const formSchema = z.object({
    emailAddress: z.string().email("You must specify a valid email address."),
    password: z
      .string()
      .min(12, "Your password must be at least 12 characters."),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailAddress: "",
      password: "",
    },
  });

  const sendSignupRequest = async (
    emailAddress: string,
    password: string,
  ): Promise<void> => {
    const headers = await genBasicApiHeader();
    const resp = await fetch(apiUrl("/api/secure/signup"), {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: headers,
      redirect: "follow",
      body: JSON.stringify({
        emailAddress: emailAddress,
        password: password,
      }),
    });
    const resp_body = await resp.json();

    return new Promise((accept, reject) => {
      if (resp.status === 200) {
        accept();
      } else {
        reject(resp_body.error);
      }
    });
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setState({ ...state, isInFlight: true });

    sendSignupRequest(values.emailAddress, values.password).then(
      () => {
        const redirect = queryParams.get("redirect") || "/";
        navigate(redirect);
      },
      (error: string) => {
        setState({ isInFlight: false, serverErrors: [error.toString()] });
      },
    );
  };

  const errorBox = !state.serverErrors
    ? null
    : state.serverErrors.map((error) => {
        return (
          <Alert key={error} className="text-white bg-red-500">
            <CircleX color="#ffffff" className="w-4 h-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        );
      });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {errorBox}
        <FormField
          control={form.control}
          name="emailAddress"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email Address</FormLabel>
              <FormControl>
                <Input placeholder="janedoe@example.com" {...field} />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  placeholder="Super Secret Password"
                  type="password"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-xs" />
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full" disabled={state.isInFlight}>
          Sign Up
        </Button>
      </form>
    </Form>
  );
}

export default function SignupPage() {
  useEffect(() => {
    document.title = "Comfy Video - Sign Up";
  }, []);

  return (
    <div className="container py-16 space-y-8 grow">
      <Card className="mx-auto max-w-md">
        <CardHeader>
          <CardTitle className="text-2xl">Sign Up</CardTitle>
        </CardHeader>
        <CardContent>
          <SignupForm />
        </CardContent>
      </Card>
    </div>
  );
}
