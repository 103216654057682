import { ChangeEvent, InputHTMLAttributes, forwardRef, useState } from "react";
import { Input } from "./input";
import { useDebouncedCallback } from "use-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

type AsyncEventHandler = (e: ChangeEvent<HTMLInputElement>) => Promise<void>;

interface AutoSaveInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange: AsyncEventHandler;
}

const AutoSaveInput = forwardRef<HTMLInputElement, AutoSaveInputProps>(
  function AutoSaveInput({ onChange, ...props }, ref) {
    const [isWorking, setIsWorking] = useState(false);

    const suspense = (onChangeFn: AsyncEventHandler) => {
      const wrapper = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isWorking) setIsWorking(true);

        onChangeFn(e).finally(() => {
          setIsWorking(false);
        });
      };

      return wrapper;
    };

    const capturedOnChange =
      onChange ||
      (() => {
        return new Promise((resolve) => resolve());
      });
    const suspendedOnChange = suspense(capturedOnChange);
    const debouncedOnChange = useDebouncedCallback(suspendedOnChange, 500);

    const maybeLoaderIcon = isWorking ? (
      <FontAwesomeIcon icon={faCircleNotch} className="worker-indicator" />
    ) : null;
    const workingClass = isWorking ? "working" : "";

    return (
      <div
        className={`autosave-textarea ${workingClass}`}
        style={{ position: "relative" }}
      >
        <div className="screen rounded-md"></div>
        <Input ref={ref} onChange={debouncedOnChange} {...props}></Input>
        {maybeLoaderIcon}
      </div>
    );
  }
);

export default AutoSaveInput;
