import { UserInfo } from "@/lib/auth";
import { Navigate, useOutletContext } from "react-router-dom";

export default function RequireSession({
  children,
}: {
  children: JSX.Element;
}) {
  const [userInfo]: Array<UserInfo | null> = useOutletContext();

  if (userInfo === null) {
    return <Navigate to="/auth/signin" />;
  }

  return children;
}
