import { apiUrl } from "./api";
import { isDev } from "./feature_flags";

export const RAW_QUALITY_LABELS = ["360", "720", "1080", "ORIGINAL"];
export const DISPLAYABLE_QUALITY_LABELS = [
  "360p",
  "720p",
  "1080p",
  "Source Quality",
];
export const DISPLAYABLE_QUALITY_SLUGS = ["360p", "720p", "1080p", "Source"];

export function qualityToLabel(quality: string): string {
  return DISPLAYABLE_QUALITY_LABELS[RAW_QUALITY_LABELS.indexOf(quality)];
}

export function qualityToSlug(quality: string): string {
  return DISPLAYABLE_QUALITY_SLUGS[RAW_QUALITY_LABELS.indexOf(quality)];
}

export interface PlaylistPaths {
  [index: string]: string | null;

  360: string | null;
  720: string | null;
  1080: string | null;
  ORIGINAL: string | null;
}

export interface VideoMetadata {
  slug: string;
  uploaderId: number;
  storageUriBase: string;
  streamUri: string | null;
  title: string;
  description: string;
  views: number;
  uploadTs: number;
  playlistBasePaths: PlaylistPaths;
}

export interface OwnedVideoMetadata {
  videos: Array<VideoMetadata>;
}

export async function loadVideoMetadata(
  slug: string,
): Promise<VideoMetadata | null> {
  const resp = await fetch(apiUrl(`/api/videos/${slug}`), {
    credentials: isDev() ? "include" : "same-origin",
  });

  if (resp.status === 404) {
    return null;
  } else if (resp.status === 200) {
    return await resp.json();
  } else {
    const errorResp = await resp.json();
    throw new Error(errorResp.error);
  }
}

export async function loadOwnedVideoMetadata(): Promise<Array<VideoMetadata> | null> {
  const resp = await fetch(apiUrl(`/api/videos/`), {
    credentials: isDev() ? "include" : "same-origin",
  });

  if (resp.status === 200) {
    const result: OwnedVideoMetadata = await resp.json();
    return result.videos;
  } else {
    const errorResp = await resp.json();
    throw new Error(errorResp.error);
  }
}

export function sortQualities(qualities: Array<string>): Array<string> {
  const availableQualities = qualities.sort((a, b) => {
    return RAW_QUALITY_LABELS.indexOf(a) - RAW_QUALITY_LABELS.indexOf(b);
  });

  return availableQualities;
}

export function getHighestQuality(video: VideoMetadata): string | null {
  const sortedQualities = sortQualities(
    Object.getOwnPropertyNames(video.playlistBasePaths),
  );

  if (sortedQualities.length === 0) {
    return null;
  }

  return sortedQualities[sortedQualities.length - 1];
}

export function getLowestQuality(video: VideoMetadata): string | null {
  const sortedQualities = sortQualities(
    Object.getOwnPropertyNames(video.playlistBasePaths),
  );

  if (sortedQualities.length === 0) {
    return null;
  }

  return sortedQualities[0];
}
