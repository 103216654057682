import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { apiUrl, genBasicApiHeader } from "@/lib/api";
import { cdnUrl } from "@/lib/cdn";
import { CF_TURNSTILE_KEY } from "@/lib/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircleX } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

export default function SigninPage() {
  useEffect(() => {
    document.title = "Comfy Video - Log In";
  }, []);

  const [state, setState] = useState<{
    isInFlight: boolean;
    serverErrors: Array<string>;
    cfChallengeResponse: string | null;
  }>({
    isInFlight: false,
    serverErrors: [],
    cfChallengeResponse: null,
  });

  const [queryParams] = useSearchParams();
  const redirectUri = queryParams.get("redirect");
  const signupUri = redirectUri
    ? `/auth/signup?redirect=${redirectUri}`
    : "/auth/signup";

  const navigate = useNavigate();

  const formSchema = z.object({
    emailAddress: z.string().email("You must specify a valid email address."),
    password: z.string(),
    cfChallengeResponse: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailAddress: "",
      password: "",
      cfChallengeResponse: "",
    },
  });

  const sendSignupRequest = async (
    emailAddress: string,
    password: string,
  ): Promise<void> => {
    const headers = await genBasicApiHeader();
    const resp = await fetch(apiUrl("/api/secure/signin"), {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: headers,
      redirect: "follow",
      body: JSON.stringify({
        emailAddress: emailAddress,
        password: password,
        cfChallengeResponse: state.cfChallengeResponse,
      }),
    });
    const resp_body = await resp.json();

    return new Promise((accept, reject) => {
      if (resp.status === 200) {
        accept();
      } else {
        reject(resp_body.error);
      }
    });
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setState({ ...state, isInFlight: true });

    sendSignupRequest(values.emailAddress, values.password).then(
      () => {
        const redirect = queryParams.get("redirect") || "/";
        navigate(redirect);
      },
      (error: string) => {
        setState({
          ...state,
          isInFlight: false,
          serverErrors: [error.toString()],
        });
      },
    );
  };

  const errorBox = !state.serverErrors
    ? null
    : state.serverErrors.map((error) => {
        return (
          <Alert key={error} className="text-white bg-red-500">
            <CircleX color="#ffffff" className="w-4 h-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        );
      });

  const catArtUrl = cdnUrl("/images/cat.png");

  const turnstileBoxRef = useRef<HTMLDivElement>(null);
  const turnstileBox = (
    <div
      className="cf-turnstile"
      data-sitekey={CF_TURNSTILE_KEY}
      data-callback="javascriptCallback"
      data-theme="auto"
      ref={turnstileBoxRef}
    ></div>
  );

  useEffect(() => {
    if (state.cfChallengeResponse !== null) return;

    // @ts-expect-error This is needed because turnstile is set globally by
    // the Cloudflare turnstile script in index.html
    turnstile.render(turnstileBoxRef.current, {
      sitekey: CF_TURNSTILE_KEY,
      callback: (token: string) => {
        setState({ ...state, cfChallengeResponse: token });
      },
    });
  }, [state]);

  return (
    <div className="container">
      <Card className="overflow-hidden">
        <div className="lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
          <div className="flex items-center justify-center py-12">
            <div className="mx-auto grid w-[450px] gap-6">
              <div className="grid gap-2 text-center mb-4">
                <h1 className="text-3xl font-bold">Sign In</h1>
              </div>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <div className="grid gap-8">
                    {errorBox}
                    <FormField
                      control={form.control}
                      name="emailAddress"
                      render={({ field }) => (
                        <FormItem className="grid gap-2">
                          <FormLabel>Email Address</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="janedoe@example.com"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem className="grid gap-2">
                          <div className="flex items-center">
                            <FormLabel>Password</FormLabel>
                            <Link
                              to="/auth/reset-password"
                              className="ml-auto inline-block text-sm text-link"
                            >
                              Forgot your password?
                            </Link>
                          </div>
                          <FormControl>
                            <Input
                              placeholder="Super Secret Password"
                              type="password"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />
                    {turnstileBox}
                    <Button
                      type="submit"
                      className="w-full"
                      disabled={
                        state.isInFlight || state.cfChallengeResponse === null
                      }
                    >
                      Sign In
                    </Button>
                    <div className="mt-4 text-center text-sm">
                      Don&apos;t have an account?{" "}
                      <Link to={signupUri} className="text-link">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </form>
              </Form>
            </div>
          </div>
          <div
            className="hidden bg-cover lg:block"
            style={{
              backgroundImage: `url(${catArtUrl})`,
            }}
          ></div>
        </div>
      </Card>
    </div>
  );
}
