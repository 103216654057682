import { LocalizedUnixTime } from "@/components/datetime";
import { Alert, AlertDescription } from "@/components/ui/alert";
import AutoSaveInput from "@/components/ui/autosave-input";
import AutoSaveTextarea from "@/components/ui/autosave-textarea";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { PlayerType, VidStackPlayer } from "@/components/video-player";
import { ApiError, apiUrl, genBasicApiHeader } from "@/lib/api";
import { UserInfo } from "@/lib/auth";
import { cdnUrl } from "@/lib/cdn";
import { siteUrl } from "@/lib/utils";
import {
  DISPLAYABLE_QUALITY_LABELS,
  getHighestQuality,
  RAW_QUALITY_LABELS,
  VideoMetadata,
} from "@/lib/video";
import { Label } from "@radix-ui/react-label";
import { Separator } from "@radix-ui/react-separator";
import { Check, CircleX, Copy, MoveLeft, Squirrel, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import {
  Await,
  Link,
  Navigate,
  useLoaderData,
  useOutletContext,
} from "react-router-dom";

function formatPageTitle(maybeVideoTitle: string | null): string {
  if (!maybeVideoTitle) {
    return "Video Manager";
  } else {
    return `Video Manager - ${maybeVideoTitle}`;
  }
}

function CopyIcon(props: { url: string }) {
  const [wasClicked, setClicked] = useState(false);

  const icon = wasClicked ? (
    <Check size={14} />
  ) : (
    <Copy
      size={14}
      onClick={() => {
        navigator.clipboard.writeText(props.url);
        setClicked(true);
      }}
    />
  );

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{icon}</TooltipTrigger>
        <TooltipContent>
          <p>Copy link to clipboard</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

function MetadataTable(props: { metadata: VideoMetadata }) {
  const qualityRows = RAW_QUALITY_LABELS.map((label, idx) => {
    return (
      <TableRow key={label}>
        <TableCell>Available in {DISPLAYABLE_QUALITY_LABELS[idx]}</TableCell>
        <TableCell>
          {props.metadata.playlistBasePaths[label] ? (
            <Check size={16} className="text-green-500" />
          ) : (
            <X size={16} className="text-red-500" />
          )}
        </TableCell>
      </TableRow>
    );
  });

  const fullUrl = siteUrl(`/v/${props.metadata.slug}`);

  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Upload Date</TableCell>
            <TableCell className="align-middle">
              <LocalizedUnixTime timestamp={props.metadata.uploadTs} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Watchable at</TableCell>
            <TableCell className="align-middle">
              {/* Specifically do not link to an absolute URL here so local dev doesn't break. */}
              <Link to={`/v/${props.metadata.slug}`} className="text-link mr-2">
                {siteUrl(`/v/${props.metadata.slug.substring(0, 18)}...`)}
              </Link>
              <CopyIcon url={fullUrl} />
            </TableCell>
          </TableRow>
          {qualityRows}
        </TableBody>
      </Table>
    </div>
  );
}

function MetadataEditorForm(props: { metadata: VideoMetadata }) {
  const [apiError, setApiError] = useState<string | null>(null);

  useEffect(() => {
    document.title = formatPageTitle(props.metadata.title);
  }, [props.metadata.title]);

  const updateTitle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const header = await genBasicApiHeader();
    const resp = await fetch(apiUrl(`/api/videos/${props.metadata.slug}`), {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: header,
      redirect: "follow",
      body: JSON.stringify({
        field: "title",
        value: e.target.value,
      }),
    });

    if (!resp.ok) {
      const errorBody: ApiError = await resp.json();
      setApiError(errorBody.error);
    }

    document.title = formatPageTitle(e.target.value);
  };

  const updateDescription = async (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const header = await genBasicApiHeader();
    const resp = await fetch(apiUrl(`/api/videos/${props.metadata.slug}`), {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: header,
      redirect: "follow",
      body: JSON.stringify({
        field: "description",
        value: e.target.value,
      }),
    });

    if (!resp.ok) {
      const errorBody: ApiError = await resp.json();
      setApiError(errorBody.error);
    }
  };

  const errorBox = !apiError ? null : (
    <Alert className="text-white bg-red-500">
      <CircleX color="#ffffff" className="w-4 h-4" />
      <AlertDescription>{apiError}</AlertDescription>
    </Alert>
  );

  const highestQuality = getHighestQuality(props.metadata);
  let videoPlayer = (
    <Skeleton className="rounded-md flex flex-col justify-center items-center w-full h-[360px] space-y-4">
      <Squirrel className="dancing-squirrel" size={28} />
      <p>This video is still processing...</p>
    </Skeleton>
  );

  if (highestQuality !== null) {
    const playlistBasePath = props.metadata.playlistBasePaths[highestQuality];

    if (playlistBasePath !== null) {
      const videoUrl = cdnUrl(`/${playlistBasePath}stream.m3u8`);
      videoPlayer = <VidStackPlayer url={videoUrl} type={PlayerType.Mini} />;
    } else {
      console.error(
        `Playlist base path for quality ${highestQuality} was specified to be null.`,
      );
    }
  }

  return (
    <div className="grid grid-cols-2 space-x-12 place-content-between">
      <div className="min-w-128 flex flex-col gap-6">
        {errorBox}
        <div>
          <Label className="font-medium text-xl tracking-tight mb-2 block">
            Title
          </Label>
          <AutoSaveInput
            onChange={updateTitle}
            defaultValue={props.metadata.title}
          />
        </div>
        <div>
          <Label className="font-medium text-xl tracking-tight mb-2 block">
            Description
          </Label>
          <AutoSaveTextarea
            onChange={updateDescription}
            rows={5}
            defaultValue={props.metadata.description}
          />
        </div>
        <Button variant="destructive">Delete Video</Button>
      </div>
      <div className="space-y-4">
        {videoPlayer}
        <MetadataTable metadata={props.metadata} />
      </div>
    </div>
  );
}

// TODO (#123): Update metadata editor suspense to actually look like something.
function MetadataEditorSuspense() {
  return (
    <div className="video-manager-list grid grid-cols-4 gap-4">
      <Skeleton className="rounded-md flex justify-center items-center w-full h-[180px]" />
      <Skeleton className="rounded-md flex justify-center items-center w-full h-[180px]" />
      <Skeleton className="rounded-md flex justify-center items-center w-full h-[180px]" />
      <Skeleton className="rounded-md flex justify-center items-center w-full h-[180px]" />
    </div>
  );
}

function MetadataEditorPage() {
  const metadata = useLoaderData() as VideoMetadata;
  const [userInfo]: Array<UserInfo | null> = useOutletContext();

  if (userInfo === null || metadata.uploaderId !== userInfo.userId) {
    return <Navigate to="/401" />;
  }

  return (
    <main className="container">
      <aside className="space-x-2">
        <Link to="/video-manager">
          <Button variant="secondary">
            <MoveLeft className="inline mr-1" size={16} />
            Back to Video Manager
          </Button>
        </Link>
      </aside>
      <Separator className="my-4" />
      <React.Suspense fallback={<MetadataEditorSuspense />}>
        <Await resolve={metadata} errorElement={<p>Failed to load videos.</p>}>
          <MetadataEditorForm metadata={metadata} />
        </Await>
      </React.Suspense>
    </main>
  );
}

export default MetadataEditorPage;
