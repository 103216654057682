import pRetry from "p-retry";

import { ApiError, apiUrl, genBasicApiHeader } from "./api";
import { hashData } from "./crypto";

const TRANSACTION_ID_HEADER = "Comfy-Transaction-Id";
const TRANSACTION_CHUNK_HEADER = "Comfy-Transaction-Chunk";
const TRANSACTION_CHECKSUM_HEADER = "Comfy-Transaction-Checksum";

const UPLOAD_MAX_RETRIES = 3;

interface StartTransactionResponse {
  transactionId: string;
}

interface EndTransactionResponse {
  uploadId: string;
}

export async function chunkedUpload(
  cfChallengeResponse: string,
  blob: File,
  chunkSize: number,
  onProgress: (uploadedBytes: number) => void,
): Promise<string> {
  if (chunkSize <= 0) {
    throw new Error("Chunk size must be at least 1");
  }

  console.debug(`Uploading file ${blob.name} in chunks.`);

  const maybeTransaction = await startTransaction(cfChallengeResponse);

  if (maybeTransaction === null) {
    throw new Error(
      "An unexpected error occurred while trying to upload a file.",
    );
  }

  const transactionId = maybeTransaction.transactionId;

  console.debug(`Started transaction ${transactionId} for file ${blob.name}`);

  // Not sure if this is memory efficient we might just OOM someone's browser.
  const chunks: Array<[number, Blob]> = [];
  let idx = 0;

  for (let offset = 0; offset < blob.size; offset += chunkSize) {
    chunks.push([
      idx,
      blob.slice(offset, Math.min(offset + chunkSize, blob.size)),
    ]);
    idx++;
  }

  const promises = chunks.map(([idx, chunk]) =>
    pRetry(uploadChunk.bind(null, transactionId, idx, chunk), {
      retries: UPLOAD_MAX_RETRIES,
    }).then((checksum) => {
      onProgress(chunk.size);
      return checksum;
    }),
  );

  try {
    const checksums = await Promise.all(promises);
    return await pRetry(
      endTransaction.bind(null, blob.name, transactionId, checksums),
      {
        retries: UPLOAD_MAX_RETRIES,
      },
    );
  } catch (e) {
    console.error(`Failed to upload video: ${e}`);
    throw new Error(`Failed to upload video: ${e}`);
  }
}

async function startTransaction(
  cfChallengeResponse: string,
): Promise<StartTransactionResponse | null> {
  const headers = await genBasicApiHeader();

  return fetch(apiUrl("/api/transaction/start"), {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headers,
    redirect: "follow",
    body: JSON.stringify({
      cfChallengeResponse: cfChallengeResponse,
    }),
  }).then((resp) => {
    if (resp.status === 200) {
      return resp.json();
    } else {
      return null;
    }
  });
}

async function uploadChunk(
  transactionId: string,
  index: number,
  blob: Blob,
): Promise<string> {
  console.debug(`Uploading chunk ${index} for transaction ${transactionId}`);

  const headers = await genBasicApiHeader();
  headers.set(TRANSACTION_ID_HEADER, transactionId);
  headers.set(TRANSACTION_CHUNK_HEADER, "" + index);

  const data = await blob.arrayBuffer();
  const checksum = await hashData(data);

  headers.set(TRANSACTION_CHECKSUM_HEADER, btoa(checksum));

  const result = await fetch(apiUrl("/api/transaction/upload"), {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headers,
    redirect: "follow",
    body: blob,
  });

  if (result.status === 200) {
    return checksum;
  } else {
    const errorJson: ApiError = await result.json();

    console.warn(
      `Failed to upload chunk: ${JSON.stringify(
        errorJson,
      )}. Headers were: ${JSON.stringify(headers)}`,
    );

    throw new Error(errorJson.error);
  }
}

async function endTransaction(
  fileName: string,
  transactionId: string,
  checksums: Array<string>,
): Promise<string> {
  console.debug(
    `Ending transaction ${transactionId} with checksums: ${JSON.stringify(
      checksums,
    )}`,
  );

  const headers = await genBasicApiHeader();
  const resp = await fetch(apiUrl("/api/transaction/finish"), {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headers,
    redirect: "follow",
    body: JSON.stringify({
      fileName: fileName,
      transactionId: transactionId,
      checksums: checksums,
    }),
  });

  if (resp.status !== 200) {
    const error = await resp.json();
    throw new Error(error.error);
  }

  const respJson: EndTransactionResponse = await resp.json();
  return respJson.uploadId;
}
