import { LocalizedUnixTime } from "@/components/datetime";
import { Skeleton } from "@/components/ui/skeleton";
import { PlayerType, VidStackPlayer } from "@/components/video-player";
import { cdnUrl } from "@/lib/cdn";
import { getHighestQuality, VideoMetadata } from "@/lib/video";
import { Squirrel } from "lucide-react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Await, useLoaderData } from "react-router-dom";

function WatchVideoSuspense() {
  return <Skeleton className="w-full aspect-video"></Skeleton>;
}

function getHighestQualityStream(metadata: VideoMetadata): string | null {
  const highestQuality = getHighestQuality(metadata);

  if (highestQuality !== null) {
    const playlistBasePath = metadata.playlistBasePaths[highestQuality];

    if (playlistBasePath !== null) {
      return cdnUrl(`/${playlistBasePath}stream.m3u8`);
    }
  }

  return null;
}

function WatchVideoMetadata(props: { metadata: VideoMetadata }) {
  const streamUrl = getHighestQualityStream(props.metadata);

  if (streamUrl !== null) {
    return (
      <Helmet>
        <meta property="og:title" content={props.metadata.title} />
        <meta
          property="og:desciption"
          content={"Comfy Video - " + props.metadata.title}
        />
        <meta property="og:site_name" content="Comfy Video" />
        <meta property="og:video" content={streamUrl} />
        <meta property="og:video:type" content="application/x-mpegurl" />
      </Helmet>
    );
  } else {
    return (
      <Helmet>
        <meta property="og:title" content={props.metadata.title} />
        <meta property="og:site_name" content="Comfy Video" />
        <meta
          property="og:description"
          content="This video is still being processed."
        />
      </Helmet>
    );
  }
}

function WatchVideoPlayer(props: { metadata: VideoMetadata }) {
  let videoPlayer = (
    <Skeleton className="rounded-md flex flex-col justify-center items-center w-full aspect-video">
      <Squirrel className="dancing-squirrel" size={28} />
      <p>This video is still processing...</p>
    </Skeleton>
  );

  const streamUrl = getHighestQualityStream(props.metadata);

  if (streamUrl !== null) {
    videoPlayer = (
      <VidStackPlayer
        url={streamUrl}
        type={PlayerType.Regular}
        autoplay={true}
      />
    );
  }

  return videoPlayer;
}

export function WatchVideoPage() {
  const metadata = useLoaderData() as VideoMetadata;

  useEffect(() => {
    document.title = metadata.title;
  }, [metadata.title]);

  return (
    <main className="container">
      <React.Suspense fallback={<WatchVideoSuspense />}>
        <Await resolve={metadata} errorElement={<p>Failed to load videos.</p>}>
          <div className="space-y-4">
            <WatchVideoMetadata metadata={metadata} />
            <WatchVideoPlayer metadata={metadata} />
            <header>
              <h2 className="text-2xl tracking-tight">{metadata.title}</h2>
              <p className="text-sm text-muted-foreground">
                Uploaded on <LocalizedUnixTime timestamp={metadata.uploadTs} />
              </p>
            </header>
          </div>
        </Await>
      </React.Suspense>
    </main>
  );
}

export default WatchVideoPage;
