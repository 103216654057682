import { apiUrl } from "./api";
import { CF_TURNSTILE_KEY } from "./constants";
import { isDev } from "./feature_flags";

export interface UserInfo {
  userId: number;
  emailAddress: string;
}

interface UserSession {
  userId: number;
  emailAddress: string;
}

interface GetUserInfoResponse {
  session: UserSession | null;
}

export async function loadUserInfo(): Promise<UserInfo | null> {
  const resp = await fetch(apiUrl("/api/user"), {
    credentials: isDev() ? "include" : "same-origin",
  });

  if (resp.status != 200) {
    return null;
  }

  const respData: GetUserInfoResponse = await resp.json();

  if (respData.session === null) {
    return null;
  } else {
    return {
      userId: respData.session.userId,
      emailAddress: respData.session.emailAddress,
    };
  }
}

interface FetchCsrfTokenResponse {
  csrfToken: string;
}

export async function fetchCsrfToken(): Promise<string> {
  const resp = await fetch(apiUrl("/api/secure/csrf-token"));
  const respData: FetchCsrfTokenResponse = await resp.json();
  return respData.csrfToken;
}

export async function doTurnstileChallenge(
  ele: HTMLDivElement,
): Promise<string> {
  return new Promise((resolve) => {
    // @ts-expect-error The turnstile variable is automatically set by
    // the Cloudflare Turnstile script in index.html
    turnstile.render(ele, {
      sitekey: CF_TURNSTILE_KEY,
      callback: (token: string) => {
        resolve(token);
      },
    });
  });
}
