import { UserInfo } from "@/lib/auth";
import { Navigate, useOutletContext } from "react-router-dom";

export default function RequireNoSession({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [userInfo]: Array<UserInfo | null> = useOutletContext();

  if (userInfo !== null) {
    return <Navigate to="/" />;
  }

  return children;
}
