import dayjs from "dayjs";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

export function LocalizedUnixTime(props: { timestamp: number }) {
  const formattedUploadTs = dayjs
    .unix(props.timestamp)
    .format("MMM DD, YYYY [at] h:mm A");

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{formattedUploadTs}</TooltipTrigger>
        <TooltipContent>
          <p>This timestamp has been converted to your local time zone.</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
