import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

import { isDev } from "./feature_flags";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function siteUrl(uri: string): string {
  if (isDev()) {
    return `https://sandbox.comfy.video${uri}`;
  }

  return `https://comfy.video${uri}`;
}
